.fillTextInput.influenceSelect .MuiOutlinedInput-input {
  padding: 16px 12px !important;
  min-height: auto;
}

.influenceSelect {
  width: 433px !important;
  margin-bottom: 16px !important;
}

.secondSelect {
  width: 233px !important;
  margin-left: 16px !important;
}

.fillTextInput.secondSelect .MuiOutlinedInput-input {
  min-height: auto;
}

.fillTextInput .MuiInputLabel-shrink {
  display: none;
}

.MuiMenuItem-gutters {
  color: #3e4240 !important;
  font-size: 16px !important;
  line-height: 16px !important;
  background-color: #f7f9fa !important;
  box-shadow: inset 0px 1px 0px #dce5e0;
  padding: 16px 12px !important;
}

.MuiList-padding.MuiMenu-list {
  padding: 0;
}

.MuiInputLabel-formControl {
  color: #3e4240 !important;
  font-size: 16px !important;
  line-height: 16px !important;
}

.MuiInputLabel-formControl {
  color: #3e4240 !important;
  font-size: 16px !important;
  line-height: 16px !important;
}

.MuiFormControlLabel-label {
  color: #3e4240 !important;
  font-size: 16px !important;
  font-family: "Fredoka" !important;
}

.checkbox-reflective.MuiCheckbox-colorPrimary.Mui-checked {
  color: #11734a;
}



.custom-tooltip {
  width: max-content;
  height: 24px;
  padding: 4px 8px 4px 8px;
  gap: 4px;
  border-radius: 4px;
  background: #19442E;
  box-shadow: 0px 4px 6px 0px #49665D2E;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
}