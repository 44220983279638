.left-wrapper {
  // background-image: url("../../images/left-bg.png");
  // background-repeat: no-repeat;
  // background-size: 100% 100%;
  height: 100vh;
  padding: 10vh 5vw;
}

.left-wrapper-new {
  // background-image: url("../../images/left-bg.png");
  // background-repeat: no-repeat;
  // background-size: 100% 100%;
  height: 100vh;
  padding: 5vh 5vw;
}

.full-loader {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #b7bdb933;
  z-index: 111;
}

.loader-spinner {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.MuiButton-fullWidth.MuiButtonBase-root.Mui-disabled {
  color: #fff;
}

.loader-spinner > svg {
  width: 100px;
  height: 100px;
}

.MuiTextField-root {
  margin-bottom: 16px !important;
}

.MuiOutlinedInput-root {
  border-radius: 8px !important;
  background-color: #fff;
}

.MuiOutlinedInput-root fieldset {
  border: 0;
}

.MuiOutlinedInput-input {
  font-size: 16px !important;
  line-height: 16px !important;
  color: #3e4240 !important;
  font-weight: 400 !important;
  padding: 22px 13px !important;
  height: 16px !important;
}

.button-after::after {
  background-image: url("../../images/icon/button_shadow.svg");
  background-repeat: no-repeat;
}

.MuiButtonBase-root.Mui-disabled::after {
  // background-image: url("../../images/icon/button_shadow_disable.svg");
}
.registation_text_new {
  text-align: center;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
}

.registation_text_new > span {
  flex: none;
  font-size: 16px;
  line-height: 12px;
  color: #3E4240;
  font-weight: 400;
}
.registation_text {
  text-align: center;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
}

.registation_text > span {
  flex: none;
  font-size: 16px;
  line-height: 12px;
  color: #9ba19d;
  font-weight: 400;
}

.registation_text::before {
  content: "";
  border-top: 1px solid #b7bdb9;
  width: 100%;
  margin: 0 1rem 0 0;
  display: inline-block;
}

.registation_text::after {
  content: "";
  border-top: 1px solid #b7bdb9;
  width: 100%;
  margin: 0 0 0 1rem;
  display: inline-block;
}

.no-after.MuiButtonBase-root::after {
  content: none;
}

#cursor {
  border-left: 0.1em solid #5fb581;
  animation: blink 0.7s steps(1) infinite;
}

@keyframes blink {
  50% {
    border-color: transparent;
  }
}
.typeText {
  color: #5fb581;
  font-family: var(--font-family);
  font-size: 28px !important;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
}

.archiveTitle {
  color: #000;

  font-family: var(--font-family);
  font-size: 28px !important;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 48px */
  letter-spacing: 1px;
}
