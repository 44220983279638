.dialogWrapper .MuiDialog-paper {
  background-color: #fff;
  border-radius: 20px;
  padding: 80px 40px 60px 40px;
  max-width: 540px;
  overflow: unset;
}
@media screen and (max-width: 768px) {
  .dialogWrapper .MuiDialog-paper {
    border-radius: 0px;
    width: 100%;
  }
  .dialogWrapper .MuiPaper-root {
    margin: 0 !important;
    bottom: 0;
    position: absolute;
    height: 60vh;
    width: 100%;
    max-width: 100%;
    max-height: 100%;
    // border-radius: 40px 40px 0px 0px;
    padding: 40px 32px 32px !important;
    // padding-top: 40px !important;
  }
}
.picker-container {
  width: 80%;
  margin: 0 auto;
}
.picker-container .picker-column .picker-item {
  font-size: 20px !important;
  font-weight: 400;
}
.picker-container .picker-highlight:before, .picker-container .picker-highlight:after {
  width: 0%;
}
.picker-container .picker-column .picker-item.picker-item-selected {
  font-size: 26px !important;
  font-weight: 500;
  z-index: 100;
  color: #3E4240;
  background-color: #EBF2F1 !important;
  border-radius: 4px;
}
.picker-container .picker-highlight {
  // background-color: #EBF2F1 !important;
  z-index: inherit;
}
.dialogWrapper .MuiBackdrop-root {
  background-color: rgba(147, 155, 152, 0.7);
}

.dialogWrapper .MuiDialogContent-root {
  padding: 0;
}

.tracking-btn.MuiButton-contained {
  margin-bottom: 0 !important;
}

// .listItem.active {
//   background-color: transparent !important;
// }

.listItem.active > svg > path {
  // stroke-opacity: 1;
  // stroke-width: 1.7px;
  fill: var(--color-text-primary);
}
// .listItem.active > svg > circle {
//   stroke: var(--color-black);
// }
.listItem:hover {
  li {
    color: var(--color-new-main) !important;
  }
  svg > path {
    fill: var(--color-new-main);
  }
}
// .listItem:last-child.active > svg > path {
//   stroke: var(--color-black);
//   fill: transparent;
// }

.listItem.active > li {
  color: var(--color-text-primary);
  font-weight: 600 !important;
}

// .sidebar:after {
//   content: '""';
//   position: absolute;
//   bottom: 0;
//   background: url(../../images/Illustration_Navbar.png) 100% 100%;
//   background-repeat: no-repeat;
//   width: 113px;
//   height: 249px;
//   left: 0;
// }

.searchWrapper .MuiOutlinedInput-input {
  color: #b7bdb9 !important;
  background: #f7f9fa;
  border: 1px solid #dce6e0;
  border-radius: 8px;
  padding: 16px 40px 16px 12px !important;
  width: 300px;
}

.searchWrapper .MuiTextField-root {
  margin-bottom: 0 !important;
}

.searchWrapper {
  position: relative;
  z-index: 1;
}

// .searchWrapper::after {
//   content: "";
//   background: url(../../images/icon/searchIconGreen.svg) 100% 100%;
//   position: absolute;
//   right: 15px;
//   background-repeat: no-repeat;
//   width: 20px;
//   height: 20px;
//   top: 15px;
// }

.track-title {
  position: relative;
}

.track-title::after {
  content: "";
  background: url(../../images/TopRight.png) 100% 100%;
  position: absolute;
  right: 0;
  top: 0;
  width: 224px;
  height: 164px;
}

.dialogWrapper-tracking .MuiDialog-paper {
  max-width: 872px;
  padding: 48px 40px;
}
.dialogWrapper-tracking-cal .MuiDialog-paper {
  max-width: 90vw;
  padding: 48px 40px;
}
@media screen and (max-width: 768px) {
  .dialogWrapper-tracking .MuiDialog-paper {
    margin: 0;
    position: absolute;
    bottom: 0;
    min-width: 100%;
    width: 100%;
  }
}
@media screen and (max-width: 768px) {
  .dialogWrapper-mobileScreen .MuiDialog-paper {
    margin: 0%;
    position: absolute;
    bottom: 0%;
    min-width: 100%;
    width: 100%;
  }
}
@media screen and (max-width: 768px) {
  .dialogWrapper-addHoursModal .MuiDialog-paper {
    margin: 0%;
    position: absolute;
    bottom: 0%;
    min-width: 100%;
    width: 100%;
  }
}
.dialogWrapper-tracking .MuiDialogTitle-root {
  padding: 0;
}

.dialogWrapper-tracking .MuiTypography-h6 {
  font-size: 26px;
  line-height: 26px;
  color: #3e4240;
  font-weight: 700;
  letter-spacing: 0.02em;
  font-family: var(--font-family);
  margin-bottom: 40px;
}

// @media screen and (max-width: 600px) {
//   .dialogWrapper-YesNoModal .MuiDialog-paper {
//     margin-top: 810px;
//     width: 100%;
//   }
// }

.textarea {
  background-color: #fff;
  border: 2px solid #e3e3e3 !important;
  border-radius: 10px;
  margin-bottom: 40px !important;
}

.textarea .MuiInput-root {
  padding: 16px;
}

.textarea .MuiInputBase-input {
  font-family: var(--font-family);
}

.textarea .MuiOutlinedInput-input {
  padding: 0 !important;
  height: 67px !important;
}

.textField {
  background-color: #fff;
  border: 2px solid #e3e3e3 !important;
  border-radius: 10px;
  margin-bottom: 40px !important;
}

.textField .MuiInput-root {
  padding: 16px;
}

.textField .MuiInputBase-input {
  font-family: var(--font-family);
}

.textField .MuiOutlinedInput-input {
  height: 10px !important;
  width: 40px !important;
}

.button-after::after {
  background-image: url("../../images/icon/button_shadow.svg");
  background-repeat: no-repeat;
}

.dialogWrapper-outcome .MuiDialog-paper {
  max-width: 1000px;
  padding: 0;
}

.dialogWrapper-outcome .MuiDialog-paperFullScreen.MuiDialog-paper {
  max-width: 80%;
  margin: 40px;
  height: auto;
  max-height: 700px;
}

.dialogWrapper-outcome .MuiDialogContent-root {
  padding-top: 0 !important;
  border-radius: 20px;
}

.dialogWrapper-outcome .MuiDialogContent-root > div {
  height: auto;
}

@media screen and (max-width: 768px) {
  .dialogWrapper-outcome .MuiDialog-paperFullScreen.MuiDialog-paper {
    max-width: 100%;
    max-height: 100%;
    height: 100%;
    margin: 0;
  }
}

.tracking-button {
  width: 144px;
  margin-bottom: 0 !important;
}

.tracking-button-new {
  width: 202px;
  padding: 16px 20px !important;
  border-radius: 100px !important;
  border: 2px solid #f3f3f3 !important;
  background: #fff;
  font-size: 16px;
  color: #000;
  font-weight: 400;
  line-height: 100%; 
}
.tracking-button-contained-new {
  width: 202px;
  padding: 16px 20px !important;
  border-radius: 100px !important;
  background: #fff;
  font-size: 16px;
  color: #FFF;
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  line-height: 100%; 
}

.tracking-button:last-child:after {
  content: unset !important;
}

.add-hours {
  margin-bottom: 20px !important;
  width: 100% !important;
}

.radio-wrapper {
  flex-direction: initial !important;
  padding: 0 5px;
  margin-bottom: 20px;
}

.add-hours.fillTextInput .MuiOutlinedInput-root {
  background: #fff;
}

.calendar .calenderItem:first-child button {
  visibility: initial;
}

.MuiIconButton-sizeMedium.Mui-disabled {
  color: rgba(0, 0, 0, 0.26) !important;
  border: 1px solid rgba(0, 0, 0, 0.26) !important;
}

.dialogWrapper-share .MuiDialog-paper {
  max-width: 85%;
  padding: 48px 40px;
}
.dialogWrapper-share-cal .MuiDialog-paper {
  max-width: 90vw;
  padding: 48px 40px;
}
@media screen and (max-width: 768px) {
  .dialogWrapper-share .MuiDialog-paper {
    margin: 0;
    position: absolute;
    bottom: 0;
    min-width: 100%;
    width: 100%;
  }
}

.dialogWrapper-addContact .MuiDialog-paper {
  max-width: 50%;
  padding: 48px 40px;
}
.dialogWrapper-addContact-cal .MuiDialog-paper {
  max-width: 90vw;
  padding: 48px 40px;
}
@media screen and (max-width: 768px) {
  .dialogWrapper-addContact .MuiDialog-paper {
    margin: 0;
    position: absolute;
    bottom: 0;
    min-width: 100%;
    width: 100%;
  }
}

.dialogWrapper-addContact .MuiDialogTitle-root {
  padding: 0;
  .MuiTypography-h6 {
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    line-height: 100%;
    /* identical to box height, or 26px */

    letter-spacing: 0.02em;

    /* TextColors/DarkGrey */

    color: #3e4240;
  }
}
.dialogWrapper-addContact {
  padding: 0;
  .MuiDialogContent-root {
    .MuiTypography-h6 {
      font-style: normal;
      font-weight: 600;
      font-size: 22px;
      line-height: 100%;
      /* identical to box height, or 22px */

      /* TextColors/DarkGrey */

      color: #3e4240;
    }
    .MuiTreeItem-iconContainer {
      width: 0;
    }
    .MuiTreeItem-label {
      font-weight: 500;
      font-size: 16px;
      line-height: 100%;
      /* identical to box height, or 16px */

      display: flex;
      align-items: center;

      /* TextColors/DarkGrey */

      color: #3e4240;
    }
  }
}
.dialogWrapper-share .MuiDialogTitle-root {
  padding: 0;
  .MuiTypography-h6 {
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    line-height: 100%;
    /* identical to box height, or 26px */

    letter-spacing: 0.02em;

    /* TextColors/DarkGrey */

    color: #3e4240;
  }
}
.dialogWrapper-share {
  .MuiDialogContent-root {
    .MuiTypography-h6 {
      font-style: normal;
      font-weight: 600;
      font-size: 22px;
      line-height: 100%;
      /* identical to box height, or 22px */

      /* TextColors/DarkGrey */

      color: #3e4240;
    }
    .MuiTreeItem-iconContainer {
      width: 0;
    }
    .MuiTreeItem-label {
      font-weight: 500;
      font-size: 16px;
      line-height: 100%;
      /* identical to box height, or 16px */

      display: flex;
      align-items: center;

      /* TextColors/DarkGrey */

      color: #3e4240;
    }
  }
}

.dialogWrapper-share .MuiTypography-h6 {
  font-size: 26px;
  line-height: 26px;
  color: #3e4240;
  font-weight: 700;
  letter-spacing: 0.02em;
  font-family: var(--font-family);
  margin-bottom: 40px;
}

.suggested-text {
  background-color: #D1F0BD;
}