.progress-screen {
  position: relative;
  height: 100vh;
}

.fillTextInput.weekSelect .MuiOutlinedInput-input {
  padding: 15px 12px !important;
  min-height: auto;
}

.weekSelect.fillTextInput {
  width: 300px !important;
  z-index: 1;
}

.titlePart {
  display: flex;
  align-items: center;
}

.ratingPart {
  display: flex;
  justify-content: space-between;
}

.ratingPartSelected {
  display: flex;
  justify-content: center;
}

.wellBeingCard {
  background: /* url("../../images/wellBeing.png") no-repeat bottom right */ #fff8e0;

  h6 {
    color: #e3b20f;
  }
}

.energyCard {
  background: /* url("../../images/energy.png") no-repeat top right */ #e6faf5;

  h6 {
    color: #68d7a8;
  }
}

.senseCard {
  background: /* url("../../images/sense.png") no-repeat bottom */ #ebfafc;

  h6 {
    color: #4cb3fd;
  }
}

.improveCard {
  background: /* url("../../images/improve.png") no-repeat bottom */ #e8f2ff;

  h6 {
    color: #7572fc;
  }
}

.stressCard {
  background: /* url("../../images/stress.png") no-repeat top left */ #f6f0ff;

  h6 {
    color: #a268ec;
  }
}

.css-18fvktr-MuiTableCell-root {
  font-weight: 500 !important;
}

.track-body-grid {
  display: flex;
  background-color: white;
  z-index: 1;
  width: calc(100% - 12.81%);
}

.track-body {
  width: 100%;
  background-color: white;
  overflow-y: scroll;
  height: 100vh;
}

.track-body::after {
  content: "";
  background-image: url("../../images/TopRight.png");
  background-repeat: no-repeat;
  background-size: cover;
  width: 224px;
  height: 164px;
  position: absolute;
  top: 0;
  right: 0;
}

.text-report-text {
  font-size: 26px;
  line-height: 26px;
  font-weight: 600;
  color: #11734a;
  margin: 0;
}

.text-report {
  position: relative;
  background-color: #f7f9fa;
  border-radius: 8px;
  padding: 32px;
  margin-top: 20px;
}

.text-report::before {
  content: "";
  background-image: url("../../images/CardBG.png");
  background-repeat: no-repeat;
  background-size: cover;
  width: 238px;
  height: 104px;
  position: absolute;
  top: 0;
  left: 0;
}

.text-report-button.button-after::after {
  content: none;
}

.text-report-button.button-after {
  width: 40px !important;
  height: 40px !important;
  min-width: 40px !important;
  font-weight: 400 !important;
  margin-bottom: 0 !important;
  padding: 6px 16px !important
}

.outcomeCard {
  cursor: pointer;
  padding: 20px !important;
  display: flex;
  flex-direction: column !important;
  gap: 20px;
  align-items: flex-start;
  svg {
    fill: black;
    height: 24;
    width: 24;
  }
}

.outcomeCard:hover {
  background-color: var(--color-new-main);
  svg {
    fill: var(--color-new-main);
    height: 24;
    width: 24;
  }
}

.outcomeCard:hover h5 {
  color: #fff !important;
}

.outcomeCard:hover h6 {
  color: #fff !important;
}

.outcomeCard:hover p {
  color: #fff !important;
}

.outcomeCard:hover rect {
  fill: #fff;
}

.outcomeCard:hover path {
  fill: var(--color-new-main);
  stroke: var(--color-new-main);
}

.outcomeCard:hover circle {
  fill: var(--color-new-main);
  stroke: var(--color-new-main);
}

.outcomeCard:hover .ratingPart h3 {
  color: #fff;
}

.outcomeCard:hover .ratingPart h6 {
  color: #fff;
}

.outcomeCard.active {
  background-color: var(--color-new-main);
  svg {
    fill: var(--color-new-main);
    height: 24;
    width: 24;
  }
}

.outcomeCard.active h5 {
  color: #fff !important;
}

.outcomeCard.active rect {
  fill: #fff;
}

.outcomeCard.active path {
  fill: var(--color-new-main);
  stroke: var(--color-new-main);
}

.outcomeCard.active circle {
  fill: var(--color-new-main);
  stroke: var(--color-new-main);
}

.outcomeCard.active .ratingPart h3 {
  color: #fff;
}

.outcomeCard.active .ratingPart h6 {
  color: #fff;
}

.dialogWrapper-influence .MuiDialog-paper {
  max-width: 1188px;
  padding: 48px 40px;
}

.dialogWrapper-influence .MuiDialogTitle-root {
  padding: 0;
}

.dialogWrapper-influence .MuiTypography-h6 {
  font-size: 26px;
  line-height: 26px;
  color: #3e4240;
  font-weight: 700;
  letter-spacing: 0.02em;
  font-family: "Fredoka";
  margin-bottom: 40px;
}

.learn-button {
  width: 164px;
  padding: 12px !important;
  z-index: 1;
  margin-bottom: 0 !important;
}

.learn-button:last-child:after {
  content: unset !important;
}

.timerIcon {
  color: #9BA19D;
  font-size: 12px;
  line-height: 12px;
  font-weight: 400;
  margin: 0;
}

.timerIcon>span {
  margin-right: 4px;
  vertical-align: middle;
}

.recom_desc {
  overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}