.row {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
  }
  
  .row-middle {
    align-items: center;
  }
  
  .col {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
  }
  
  .col-start {
    justify-content: flex-start;
    text-align: left;
  }
  
  .col-center {
    justify-content: center;
    text-align: center;
  }
  
  .col-end {
    justify-content: flex-end;
    text-align: right;
  }
  
  
  /* Calendar */
  
  .calendar {
    display: block;
    position: relative;
    /* width: fit-content; */
    background: #FFF;
    /* border: 1px solid var(--border-color); */
  }
  
  .calendar .header {
    text-transform: uppercase;
    font-weight: 700;
    font-size: 115%;
    padding: 1.5em 0;
    border-bottom: 1px solid var(--border-color);
  }
  
  .calendar .header .icon {
    cursor: pointer;
    transition: .15s ease-out;
  }
  
  .calendar .header .icon:hover {
    transform: scale(1.05);
    transition: .25s ease-out;
    color: var(--main-color);
  }
  
  .calendar .header .icon:first-of-type {
    margin-left: 1em;
  }
  
  .calendar .header .icon:last-of-type {
    margin-right: 1em;
  }
  
  .calendar .days {
    text-transform: uppercase;
    font-weight: 400;
    color: var(--text-color-light);
    font-size: 70%;
    padding: .75em 0;
    border-bottom: 1px solid var(--border-color);
  }
  
  .calendar .body .cell {
    position: relative;
    height: 5em;
    border-right: 1px solid var(--border-color);
    overflow: hidden;
    cursor: pointer;
    background: var(--neutral-color);
    transition: 0.25s ease-out;
  }
  
  .calendar .body .cell:hover {
    background: var(--bg-color);
    transition: 0.5s ease-out;
  }
  
  .calendar .body .selected {
    border-left: 10px solid transparent;
    border-image: linear-gradient(45deg, #1a8fff 0%,#53cbf1 40%);
    border-image-slice: 1;
  }
  
  .calendar .body .row {
    border-bottom: 1px solid var(--border-color);
  }
  
  .calendar .body .row:last-child {
    border-bottom: none;
  }
  
  .calendar .body .cell:last-child {
    border-right: none;
  }
  
  .calendar .body .cell .number {
    position: absolute;
    font-size: 82.5%;
    line-height: 1;
    /* top: .75em; */
    /* right: .75em; */
    padding-left: 10px;
    font-weight: 700;
    /* border: 2px solid red; */
    width: 100%;
    height: 32px;
    background-color: #F7F9FA;
    line-height: 32px
  }

  .calendar .body .cell .score {
    position: absolute;
    font-size: 82.5%;
    line-height: 1;
    top: 1.6em;
    /* right: .75em; */
    /* padding-left: 10px; */
    font-weight: 700;
    /* border: 2px solid red; */
    width: 32px;
    border-radius: 999px;
    margin: 0.35em;
    height: 32px;
    color: #fff;
    text-align: center;
    /* background-color: #F7F9FA; */
    line-height: 32px;
    z-index: 99;
  }

  .calendar .body .cell .note {
    position: absolute;
    font-size: 16px;
    line-height: 16px;
    top: 4.5em;
    /* right: .75em; */
    /* padding-left: 10px; */
    /* font-weight: 700; */
    /* border: 2px solid red; */
    width: 100%;
    height: calc(100% - 32px - 2.5em);
    /* border: 2px solxid red; */
    overflow-y: auto;
    padding: 10px;
    word-break: break-all;
    /* border-radius: 999px; */
    /* margin: 0.35em; */
    /* height: 32px; */
    /* text-align: center; */
    /* background-color: #F7F9FA; */
    /* line-height: 32px; */
    z-index: 99;
  }
  
  .calendar .body .disabled {
    color: var(--text-color-light);
    pointer-events: none;
  }
  
  .calendar .body .cell .bg {
    font-weight: 700;
    line-height: 1;
    color: var(--main-color);
    opacity: 0;
    font-size: 8em;
    position: absolute;
    top: .2em;
    right: .05em;
    transition: .25s ease-out;
    letter-spacing: -.07em;
  }
  
  .calendar .body .cell:hover .bg, .calendar .body .selected .bg  {
    opacity: 0.05;
    transition: .5s ease-in;
  }
  
  .calendar .body .col {
    flex-grow: 0;
    flex-basis: calc(100%/7);
    width: calc(100%/7);
  }