@media only screen and (min-width: 1600px) {
  .rightside-menu .login-right-part {
    padding: 0px 190px;
  }
}
html {
  background-image: url("./../../images/bgGoal-new.svg"),
    url("./../../images/bgimprove-new.svg"),
    url("./../../images/onboarding-bg-new.svg"),
    url("./../../images/maintenance.svg"),
    url("./../../images/bg-new-goal.svg");
  background-size: 0 0;
}
.bgImageCommon {
  background-image: url("./../../images/bgGoal-new.svg");
  height: 100vh;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.bgImageCommonNew {
  height: 100vh !important;
  width: 100% !important;
}

.bgImageCommonNew:before {
  content: " ";
  position: absolute;
  // top: 0;
  // left: 0;
  z-index: 0;

  background-image: url("./../../images/bgGoal-new.svg");
  height: 100vh !important;
  width: 100% !important;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  opacity: 40%;
}
.bgImageCommonMobile {
  height: 100vh !important;
  width: 100% !important;
}

.bgImageCommonMobile:before {
  content: " ";
  position: absolute;
  // top: 0;
  // left: 0;
  z-index: 0;

  background-image: url("./../../images/bgimprove-new.svg");
  height: 100vh !important;
  width: 100% !important;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  opacity: 40%;
}
// .bgImageCommonMobile {
//   background-image: url("./../../images/bgimprove-new.svg");
//   height: 100vh;
//   width: 100%;
//   background-size: cover;
//   background-repeat: no-repeat;
//   background-position: center;
// }

.bgUserIdentification {
  background-image: url("./../../images/onboarding-bg-new.svg");
  height: 100vh !important;
  width: 100% !important;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

// .bgUserIdentificationNew {
//   background-image: url("./../../images/onboarding-bg-new1.svg");
//   height: 100vh !important;
//   width: 100% !important;
//   background-size: cover;
//   background-repeat: no-repeat;
//   background-position: center;
// }
.bgUserIdentificationNew {
  height: 100vh !important;
  width: 100% !important;
}

.bgUserIdentificationNew:before {
  content: " ";
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;

  background-image: url("./../../images/onboarding-bg-new.svg");
  height: 100vh !important;
  width: 100% !important;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center 0px;
  opacity: 40%;
}

.bgImageGoal {
  background-image: url("./../../images/bg-new-goal.svg");
  height: 100vh !important;
  width: 100% !important;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.bgImageGoalNew {
  height: 100vh !important;
  width: 100% !important;
}

.bgImageGoalNew:before {
  content: " ";
  position: absolute;
  // top: 0;
  // left: 0;
  z-index: 0;

  background-image: url("./../../images/bg-new-goal.svg");
  height: 100vh !important;
  width: 100% !important;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  opacity: 40%;
}

// .bgImageImproveMobile {
//   background-image: url("./../../images/bg-new-goal.svg");
//   height: 100vh!important;
//   width: 100% !important;
//   background-size: cover;
//   background-repeat: no-repeat;
//   background-position: center;
// }
.bgImageImproveMobile {
  height: 100vh !important;
  width: 100% !important;
}

.bgImageImproveMobile:before {
  content: " ";
  position: absolute;
  // top: 0;
  // left: 0;
  z-index: 0;

  background-image: url("./../../images/bg-new-goal.svg");
  height: 100vh !important;
  width: 100% !important;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  opacity: 40%;
}
.bgImageImprove {
  background-image: url("./../../images/bgimprove-new.svg");
  height: 100vh !important;
  width: 100% !important;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.bgImageImproveNew {
  height: 100vh !important;
  width: 100% !important;
}

.bgImageImproveNew:before {
  content: " ";
  position: absolute;
  // top: 0;
  // left: 0;
  z-index: 0;

  background-image: url("./../../images/bgimprove-new.svg");
  height: 100vh !important;
  width: 100% !important;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  opacity: 40%;
}

.right-side-new-bg {
  border-radius: 50px;
  background: rgba(255, 255, 255, 0.5);
  max-width: 70% !important;
  padding: 20px;
}
.right-side-new-bg-mobile {
  border-radius: 20px;
  background: rgba(255, 255, 255, 0.5);
  max-width: 90% !important;
  padding: 20px;
}
.height100 {
  height: calc(100vh - 8%);
}
.right-side-improvement-bg-mobile {
  border-radius: 20px;
  background: rgba(255, 255, 255, 0.5);
  max-width: 90% !important;
  padding: 20px;
  z-index: 1;
}
.right-side-improvement-bg {
  border-radius: 50px;
  background: rgba(255, 255, 255, 0.5);
  max-width: 45% !important;
  padding: 20px;
  z-index: 1;
}
@media (min-width: 1513px) {
  .right-side-improvement-bg {
    max-width: 60% !important;
  }
  .right-side-improvement-common-bg {
    max-width: 75% !important;
  }
}
@media (max-width: 575px) and (min-width: 100px) {
  .right-side-improvement-bg {
    max-width: 70% !important;
  }
  .right-side-improvement-common-bg {
    max-width: 75% !important;
  }
}
@media (max-width: 768px) and (min-width: 575px) {
  .right-side-improvement-bg {
    max-width: 60% !important;
  }
  .right-side-improvement-common-bg {
    max-width: 75% !important;
  }
}
@media (max-width: 1000px) and (min-width: 768px) {
  .right-side-improvement-bg {
    max-width: 50% !important;
  }
  .right-side-improvement-common-bg {
    max-width: 80% !important;
    min-width: 75% !important;
  }
}
@media (max-width: 1300px) and (min-width: 1000px) {
  .right-side-improvement-common-bg {
    max-width: 80% !important;
    min-width: 75% !important;
  }
}
@media (max-width: 1500px) and (min-width: 1300px) {
  .right-side-improvement-common-bg {
    max-width: 80% !important;
    min-width: 75% !important;
  }
}
.right-side-improvement-common-bg {
  border-radius: 50px;
  background: rgba(255, 255, 255, 0.5);
  max-width: 55% !important;
  padding: 20px;
}
.right-side-improvement-common-bg-mobile {
  border-radius: 50px;
  background: rgba(255, 255, 255, 0.5);
  max-width: 90% !important;
  padding: 20px;
}
.flexColumnCenter {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 31px;
}
@media only screen and (min-width: 1513px) {
  .login-screen {
    max-width: 50% !important;
    flex-basis: 50% !important;
  }

  .rightside-menu {
    max-width: 50% !important;
    flex-basis: 50% !important;
  }

  .stepperWrapper {
    max-width: 50% !important;
  }
  .stepperWrapperNew {
    max-width: 100% !important;
  }

  .outcome-left-layout {
    max-width: 60% !important;
    flex-basis: 60% !important;
  }

  .outcome-right-layout {
    max-width: 40% !important;
    flex-basis: 40% !important;
  }
}

@media only screen and (max-width: 1500px) {
}

@media only screen and (max-width: 1400px) {
  .MuiButton-contained {
    margin-bottom: 50px !important;
  }

  .fillTextInput.fullWidth {
    width: 100% !important;
  }

  .text-report-text {
    font-size: 20px !important;
  }
}

@media only screen and (max-width: 1250px) {
  .fillTextInput.unitSelect {
    margin-left: 0px !important;
    width: 50% !important;
  }
}

@media only screen and (max-width: 1200px) {
  .fillTextInput {
    width: 100% !important;
  }

  .fillTextInput.influenceSelect {
    width: 100% !important;
  }

  .weekSelect.fillTextInput {
    width: 100% !important;
  }

  .drawerLayout .MuiDrawer-paper {
    max-width: 482px !important;
  }

  .text-report-text {
    font-size: 16px !important;
  }
}

@media only screen and (max-width: 1000px) {
  .leftside-menu {
    display: none;
  }

  .outcome-screen {
    display: block;
    max-width: 100% !important;
  }

  .stepperWrapper {
    max-width: 100% !important;
  }
  .stepperWrapperNew {
    max-width: 100% !important;
  }
  .MuiGrid-container {
    display: block !important;
  }

  .tracking_container {
    display: flex !important;
  }

  .rightside-menu {
    flex-basis: 100% !important;
    max-width: 100% !important;
    height: 100vh;
  }

  @media screen and (max-width: 768px) {
    .rightside-menu {
      height: 0vh;
    }
  }

  .drawerLayout .MuiDrawer-paper {
    max-width: 382px !important;
  }

  .MuiIconButton-root.closeIcon {
    position: relative !important;
    right: 0;
    top: 0;
    float: right;
  }

  .fillTextInput.unitSelect {
    justify-content: space-between;
  }

  .track-body-grid {
    width: calc(100vw - 68px) !important;
  }

  .progress-screen.MuiGrid-container {
    display: flex !important;
  }

  .userCard p {
    display: none;
  }

  .sidebar-grid {
    max-width: 68px !important;
  }

  .sidebar-list {
    align-items: center;
    display: flex;
    flex-direction: column;
  }

  .sidebar-listItems li {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .tab_name {
    display: none;
  }
}

@media only screen and (max-width: 575px) {
  .MuiOutlinedInput-input {
    padding: 15px 10px !important;
    font-size: 14px !important;
    line-height: 14px !important;
    height: 14px !important;
  }

  .MuiInputLabel-formControl {
    font-size: 14px !important;
    line-height: 14px !important;
  }

  .Mui-error {
    margin: 3px 0 0 0 !important;
  }

  .fillTextInput.unitSelect {
    width: 100% !important;
  }

  .MuiMenu-list .MuiMenuItem-gutters {
    padding: 5px !important;
    font-size: 13px !important;
    line-height: 13px !important;
    min-height: 30px;
  }

  .MuiTextField-root.fillTextInput .MuiOutlinedInput-input {
    font-size: 13px !important;
    line-height: 13px !important;
    min-height: auto;
  }

  .MuiTextField-root.fillTextInput.influenceSelect .MuiOutlinedInput-input {
    font-size: 13px !important;
    line-height: 13px !important;
    min-height: auto;
  }

  .MuiTextField-root.fillTextInput.unitselect .MuiOutlinedInput-input {
    font-size: 13px !important;
    line-height: 13px !important;
    min-height: auto;
  }

  .MuiSelect-select {
    font-size: 13px !important;
    line-height: 13px !important;
    padding: 5px 10px !important;
  }

  .fillTextInput .MuiInputLabel-formControl {
    font-size: 13px !important;
    line-height: 13px !important;
  }

  .dialogWrapper .MuiDialog-paper {
    padding: 20px !important;
    max-width: 100% !important;
  }
}

@media (max-width: 600px) and (min-width: 100px) {
  .new-button {
    color: var(--color-black) !important;
    .MuiButton-root {
      font-size: 18px !important;
      font-weight: 400;
      line-height: 21.78px;
      text-align: center;
    }
  }
  .new-button.Mui-disabled {
    .MuiButton-root {
      font-size: 18px !important;
      font-weight: 400;
      line-height: 21.78px;
      text-align: center;
    }

    background-color: #effbe7 !important;
    color: #a0a0a1 !important;
  }
}
