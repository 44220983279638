.fillTextInput {
  width: 300px !important;
  margin-bottom: 0 !important;
}

@media screen and (max-width: 768px) {
  .fillTextInput {
    width: auto !important;
  }
}

.fullWidth {
  width: 624px !important;
}

.fillTextInput .MuiOutlinedInput-root {
  background: #f7f9fa;
  border: 1px solid #dce6e0;
}

.fillTextInput .MuiOutlinedInput-input {
  font-size: 16px !important;
  line-height: 16px !important;
  color: #3e4240 !important;
  font-weight: 500 !important;
  padding: 16px 13px !important;
}

.outcomeButton {
  padding: 15px 49.5px !important;
  width: auto !important;
}

.outcomeButton.showBottonStepper {
  margin-bottom: 97px !important;
}

.timeline ol {
  display: flex;
}

.timeline li {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  margin: 10px;
  list-style: none;
}

.MuiStepper-horizontal {
  width: 100%;
}

.MuiStepLabel-root .MuiStepLabel-iconContainer {
  display: none;
}

.MuiStepLabel-root {
  align-items: flex-start !important;
  position: relative;
}

.MuiStep-horizontal {
  padding-left: 0 !important;
  padding-right: 0 !important;
  margin-right: 10px;
}

.MuiStepLabel-labelContainer::after {
  content: "";
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 4px;
  background-color: #11734a;
  border-radius: 4px;
}

.Mui-disabled .MuiStepLabel-labelContainer::after {
  background-color: #e3e3e3;
}

.Mui-completed.MuiStepLabel-alternativeLabel,
.Mui-active.MuiStepLabel-alternativeLabel {
  color: #11734a !important;
  font-weight: 400 !important;
}

.MuiStepLabel-alternativeLabel {
  text-align: left !important;
  color: #b7bdb9;
  font-size: 12px !important;
  line-height: 12px !important;
  font-weight: 400 !important;
  margin-top: 0 !important;
  margin-bottom: 7px;
}

.drawerLayout .MuiDrawer-paper {
  max-width: 582px;
  background-color: #11734a;
}

.closeIcon {
  position: absolute !important;
  right: 16px;
  top: 16px;
}

.MuiStep-horizontal:last-child {
  display: none;
}
