.maintenance-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f7f7f7;
  margin-top: -10px;
}

.maintenance-content {
  text-align: center;
  padding: 20px 100px ;
  border-radius: 10px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 6px;
}

.maintenance-image {
  background-image: url("./assets/images/maintenance.svg");
  height: 60vh;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.maintenance-content h1 {
  margin: 20px 0 10px 0;
  font-size: 28px;
  color: #333;
}

.maintenance-content p {
  font-size: 16px;
  color: #666;
}

.logo {
  margin-bottom: 20px;
}
