.goals-button {
    width: 144px;
    margin-bottom: 0 !important;
}

.goals-tracking-btn{
    width: 260px;
}

.goals-button:last-child:after {
    content: unset !important;
}

.tabLabel.Mui-selected{
    font-size: 22px!important;
    line-height: 22px!important;
    font-weight: 600!important;
    color:#11734A!important
}

.tabLabel{
    font-size: 22px!important;
    line-height: 22px!important;
    font-weight: 600!important;
    color: #9BA19D!important;
}

.MuiTabs-indicator{
    height: 3px!important;
}

.Settigs-main .MuiTabs-flexContainer button{
    
    padding-left: 0;
    margin-right: .7rem;
}
.Settigs-main .MuiTabs-flexContainer~.MuiTabs-indicator{
    
    border-radius: 2px !important;width: 60px !important;
    height: 3px !important;
}
.MuiAccordion-root{
    box-shadow: none !important;
}
.MuiAccordion-root::before{
    height: 0 !important;
}
.MuiAccordionSummary-root{
    padding-left: 0 !important;
}
.MuiAccordionDetails-root{
    padding-left: 0 !important;
}