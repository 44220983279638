.newInputBox {
    .MuiInputBase-root {
        background: #F7F9F5 !important;
        border-radius: 10px !important;

        input::placeholder {
            color: #3E4240 !important;
            font-weight: 400 !important;
            font-size: 16px !important;
        }
    }

}

.new-button {
    background-color: #ADE888 !important;
    border-radius: 50px !important;
    box-shadow: none !important;
    font-size: 18px !important;
    font-weight: 400 !important;
    color: #124743 !important;
}
.new-button.Mui-disabled {
    background-color: #d6f5c360 !important;
    color: #A0A0A1 !important;
}

.new-stepper {
    .MuiStepLabel-labelContainer::after {
        content: "";
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 4px;
        background-color: #ADE888;
        border-radius: 4px;
    }
    .MuiStepLabel-label {
        color: #C2C2C2 !important;
    }
    .Mui-active.MuiStepLabel-alternativeLabel {
        color: #5E5F5E !important;
    }
    .Mui-disabled .MuiStepLabel-labelContainer::after {
        background-color: #e3e3e3;
    }
}