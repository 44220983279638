div ::-webkit-scrollbar-track {
  background: #ebf2f1;
  border-radius: 10px;
}

::-webkit-scrollbar {
  overflow: auto;
  width: 4px;
  height: 4px;
  background-color: #B8D3C0;
}

::-webkit-scrollbar-thumb {
  background-color: var(--color-new-main);
}

::-webkit-scrollbar-thumb:hover {
  background: #11734a;
}

.dropShadowCategory { 
    border: 1.5px dashed #11734A !important;
    background-color: rgba(17, 115, 74, 0.05) !important;
    border-radius: 8px !important;
    height: fit-content !important;
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

:root {
  --border-color: #F3F3F3;
  --white-background: #fff;
  --black-color: #000;
  --fullwidth: 100%;
  --primary-color: #11734A;
  --disabled-color: #999;
  --weekdays: #D9D9D9;
  --contrast-light: #EFFBE7;
  --color-new-text: #30A35E;
  --svg-new-color: #A0A0A1;
  --color-svg: #A7DE85;
  --sidebar-icon-color: #B7BDB9;
  --sidebar-active-color: #5E5F5E;
  --button-active-text: #124743;
  --color-new-main: #ADE888;
  --color-new-main-shadow: #ADE88860;
  --color-black: #000000;
  --color-input-background: #F7F9FA;
  --color-input-border: #DCE5E0;
  --color-text-primary: #3E4240;
  --color-border: #EEEEEE;
  --color-text-secondary: #656565;

  --font-family: "Fredoka"
}

/* .MuiOutlinedInput-input:hover {
  border: 1px solid var(--color-new-main) !important;
} */

.suggested-text {
  background-color: #D1F0BD;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--background-light);
  transition: background-color 0.3s ease;
  font-family: "Fredoka", sans-serif !important;
}

code {
  font-family: "Fredoka", sans-serif !important;
}

/* cyrillic-ext */
@font-face {
  font-family: "Fredoka";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("assets/fonts/fredoka.ttf");
}