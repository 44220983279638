.progress-container {
  width: 100%;
  border-radius: 5px;
  position: relative;
  background-color: #EBF2F1;
}

.progress-bar {
  height: 12px;
  background: linear-gradient(
    -45deg,
    #4a9568 25%,
    #11734a 25%,
    #11734a 50%,
    #4a9568 50%,
    #4a9568 75%,
    #11734a 75%,
    #11734a 100%
  );
  background-size: 40px 40px;
  border-radius: 5px;
  position: relative;
  overflow: hidden;
  transition: width 0.1s;
}

.progress-stripes {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background: repeating-linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.2),
    rgba(255, 255, 255, 0.2) 10px,
    rgba(255, 255, 255, 0.3) 10px,
    rgba(255, 255, 255, 0.3) 20px
  ); */
  animation: moveStripes 1s linear infinite;
}

@keyframes moveStripes {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 40px 40px;
  }
}

.progress-text {
  margin-top: 10px;
  text-align: center;
  font-size: 16px;
  color: #000;
}
