/* IMPORT FONTS */
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Fredoka:wght@400;500;600;700&display=swap");

@import "./import/registation";
@import "./import/media";
@import "./import/outcome";
@import "./import/influence";
@import "./import/tracking";
@import "./import/progress";
@import "./import/goals";
@import "./import/newOnboarding";

body {
  background-color: var(--white-background) !important;
  overflow: hidden !important;
  
}
.css-muf3ii-MuiGrid-root{
  // height: inherit !important;
  // overflow: inherit !important;
}


.commonTrackBody {
  display: flex;
  overflow-x: auto !important;
  overflow-y: auto !important;
  flex-direction: row;
  height: auto !important;
}

.commonTrackBodyLeft {
  position: sticky !important;
  left: 0 !important;
  z-index: 101 !important;
  background: #fff !important;
  display: inline-table;
  // border-right: 1px solid #ccc;
}

.css-1tk1bks-MuiDivider-root{
  display: none;
}

.progress-chart {
  scrollbar-width: thin; /* For Firefox */
  scrollbar-color: var(--color-new-main) #fff; /* For Firefox */
}

/* For WebKit-based browsers (Chrome, Safari) */
.custom-scrollbar::-webkit-scrollbar {
  height: 4px; /* Height of horizontal scrollbar */
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #0c3c1c; /* Scrollbar thumb color */
  border-radius: 10px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background-color: #e5e5e5; /* Scrollbar track color */
}